/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-dupe-keys */
import {
  Box,
  Center,
  Flex,
  IconButton,
  Skeleton,
  Table,
  TableContainer,
  Spinner,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdDelete, MdEdit } from 'react-icons/md'
import { Pagination } from '../../components/pagination'
import * as dateUtils from '../../utils/date.utils'
import {
  sectionDetailedTrack,
  GA_CATEGORY_LEAVE,
} from '../repository/repo.utils'
import LeaveEditForm from './leaveTableEditForm'
import { useLanguageContext } from '../../context/locale.context'

const headerStyle = {
  color: 'white !important',
  position: '-webkit-sticky',
  position: 'sticky',
  top: '0px',
  zIndex: '9',
  borderRight: '1px solid white',
}

export const LeaveList = ({ leaveList, isLoading, onDeleteLeave, onEdit }) => {
  const [tableData, setTableData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [isEditMode, setIsEditMode] = useState(null)
  const {
    state: { locale },
  } = useLanguageContext()
  const changePage = page => {
    const startIndex = (page - 1) * pageSize
    const endIndex =
      startIndex + pageSize > leaveList.length
        ? leaveList.length
        : startIndex + pageSize
    let tempTable = leaveList.slice(startIndex, endIndex)
    setTableData(tempTable)
    setCurrentPage(page)
  }
  const onPageSizeChange = newPageSize => {
    const startIndex = 0
    const endIndex =
      startIndex + newPageSize > leaveList.length
        ? leaveList.length
        : startIndex + newPageSize
    let tempTable = leaveList.slice(0, endIndex)
    setTableData(tempTable)
    setCurrentPage(1)
    setPageSize(newPageSize)
  }

  useEffect(() => {
    if (leaveList.length > pageSize) {
      let tempTable = leaveList.slice(
        currentPage === 1 ? 0 : currentPage,
        pageSize
      )
      setTableData(tempTable)
    } else {
      setTableData(leaveList)
    }
  }, [leaveList])

  const handleCancel = () => {
    setIsEditMode(null)
  }
  const handleSave = data => {
    if (!!data) {
      onEdit(data)
      setIsEditMode(null)
    }
  }
  const switchEditMode = index => {
    setIsEditMode(index + 1)
  }
  const renderTableBody = () => {
    return tableData.length > 0 ? (
      tableData.map((row, index) => {
        return (
          <>
            {!!isEditMode && isEditMode === index + 1 ? (
              <LeaveEditForm
                row={row}
                rowNumber={(currentPage - 1) * pageSize + index + 1}
                onCancel={() => handleCancel(null)}
                onSave={handleSave}
                leaveList={leaveList}
              />
            ) : (
              <Tr
                bg="white"
                key={Math.random() * Date.now()}
                my={1}
                p={2}
                borderRadius="md"
                boxShadow="sm"
              >
                <Td p="2" pl="20px">
                  {(currentPage - 1) * pageSize + index + 1}
                </Td>
                <Td p="2" pl="20px" textAlign="center">
                  {dateUtils.getLeaveTimeDate(row.asset_leave_start_datetime)}
                </Td>
                {/* TODO: fix the time representation */}
                <Td p="2" pl="20px" textAlign="center">
                  {dateUtils.getLeaveTimeDate(row.asset_leave_end_datetime)}
                </Td>
                <Td p="2" pl="20px" textAlign="right">
                  {/*
                  TODO: Removed as it's causing issue in duplications
                  */}
                  <IconButton
                    bg="white"
                    borderRadius="md"
                    boxShadow="md"
                    aria-label="edit"
                    size="sm"
                    _focus={{
                      bg: 'secondary',
                    }}
                    _hover={{
                      bg: 'secondary',
                    }}
                    icon={<MdEdit w={5} h={5} />}
                    onClick={() => {
                      sectionDetailedTrack({
                        category: GA_CATEGORY_LEAVE,
                        action: 'Leave List',
                        label: 'Toggle Edit Mode',
                      })
                      switchEditMode(index)
                    }}
                  />
                  <IconButton
                    bg="white"
                    borderRadius="md"
                    boxShadow="md"
                    aria-label="remove"
                    size="sm"
                    _focus={{
                      bg: 'secondary',
                    }}
                    _hover={{
                      bg: 'secondary',
                    }}
                    icon={<MdDelete w={5} h={5} />}
                    onClick={() => {
                      sectionDetailedTrack({
                        category: GA_CATEGORY_LEAVE,
                        action: 'Leave List',
                        label: 'Delete Leave',
                      })
                      onDeleteLeave(row)
                    }}
                    ml={1}
                  />
                </Td>
              </Tr>
            )}
          </>
        )
      })
    ) : (
      <Tr>
        <Td colSpan={9}>{locale["You don't have any leave applied!"]} </Td>
      </Tr>
    )
  }

  if (!!isLoading) {
    return (
      <Box w="full">
        <Center>
          <Spinner color="brand.900" size="lg" />
        </Center>
      </Box>
    )
  }
  return (
    <Box w="full">
      {tableData.length > 0 ? (
        <>
          <TableContainer
            h="55vh"
            overflowY="scroll"
            overflowX="visible"
            sx={{
              '::-webkit-scrollbar': {
                width: '3px',
              },
              '::-webkit-scrollbar-thumb': {
                outline: `1px solid ${localStorage.getItem('color')}`,
                borderRadius: '50px',
                border: 'solid 3px transparent',
                backgroundClip: 'content-box',
                WebkitBackgroundClip: 'content-box',
              },
            }}
          >
            <Table
              style={{
                // position: 'relative',
                borderCollapse: 'separate',
                borderSpacing: '0px 10px',
              }}
            >
              <Thead bg="#4daf89">
                <Tr my={3} boxShadow="sm">
                  <Td sx={headerStyle}>#</Td>
                  <Td textAlign="center" sx={headerStyle}>
                    {locale['Start Date']}
                  </Td>
                  <Td textAlign="center" sx={headerStyle}>
                    {locale['End Date']}
                  </Td>
                  <Td textAlign="right" sx={headerStyle}>
                    {locale['Action']}
                  </Td>
                </Tr>
              </Thead>
              <Tbody>{renderTableBody()}</Tbody>
              <Tfoot>
                <Tr>
                  <Td colSpan={9}></Td>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>
          <Pagination
            limit={pageSize}
            page={currentPage}
            total={leaveList.length}
            setlimit={onPageSizeChange}
            setpage={changePage}
          />
        </>
      ) : (
        <Center>
          <Text mt="20px">{locale["You don't have any leave applied!"]}</Text>
        </Center>
      )}
    </Box>
  )
}

export default LeaveList
