import {
  HStack,
  VStack,
  Heading,
  List,
  ListItem,
  Flex,
  Avatar,
  Text,
  Divider,
  Center,
  Box,
  Button,
  Stack,
  Input,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  FormControl,
  FormLabel,
} from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import React, { useState } from 'react'
import { sectionDetailedTrack } from '../../../repository/repo.utils'
import { GA_CATEGORY_DIALOG_BOX } from './../../../repository/repo.utils'
import { useLanguageContext } from '../../../../context/locale.context'

const AddExtParticipant = ({
  selectedUsers,
  handleUserSelect,
  removeSelectedUser,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [designation, setDesignation] = useState('')
  const [error, setError] = useState('')
  const {
    state: { locale },
  } = useLanguageContext()
  const handleInput = n => e => {
    if (n === 'name') {
      setName(e.target.value)
    } else if (n === 'designation') {
      setDesignation(e.target.value)
    } else if (n === 'email') {
      setEmail(e.target.value)
    }
    setError('')
  }
  const addEmail = () => {
    const createRegExp = (str, opts) =>
      new RegExp(str.raw[0].replace(/\s/gm, ''), opts || '')
    const email_regex = createRegExp`
      ^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|
      (\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|
      (([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$`
    if (!name || !designation || !email) {
      setError('Enter all fields')
    } else {
      if (email.match(email_regex)) {
        handleUserSelect({
          email,
          name,
          designation,
        })
        setEmail('')
        setName('')
        setDesignation('')
        setError('')
      } else {
        setError('Enter valid email')
      }
    }
  }

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="md" bg="white" minW="50vw">
        <ModalCloseButton zIndex="100" />
        <ModalBody p={0} w="100%" position="relative">
          <Stack display="flex" paddingTop={5}>
            <VStack
              h="calc(100% - 100px)"
              borderRightRadius="sm"
              flex={1}
              display="flex"
            >
              <Box w="100%" my={0} py={0} mt="0px">
                <Heading
                  textTransform="uppercase"
                  size="md"
                  color="brand.900"
                  fontWeight="800"
                  mx={5}
                  my={5}
                >
                  {locale['Invite Guests']}
                </Heading>
                <Box px={10}>
                  <FormControl>
                    <FormLabel>{locale['Name']}</FormLabel>
                    <Input
                      value={name}
                      autoFocus={true}
                      placeholder="Name"
                      required
                      onChange={handleInput('name')}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>{locale['Designation']}</FormLabel>
                    <Input
                      value={designation}
                      placeholder="Designation"
                      required
                      onChange={handleInput('designation')}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>{locale['Email']}</FormLabel>
                    <Input
                      value={email}
                      placeholder="Email"
                      required
                      onChange={handleInput('email')}
                    />
                  </FormControl>
                  <Text my={2} color="red">
                    {error}
                  </Text>
                  <Button
                    variant="filled"
                    bg={localStorage.getItem('color')}
                    color="white"
                    borderRadius={6}
                    fontWeight="400"
                    boxShadow="md"
                    size="md"
                    w="100px"
                    my={5}
                    onClick={() => {
                      sectionDetailedTrack({
                        category: GA_CATEGORY_DIALOG_BOX,
                        action: 'Add Participant',
                        label: 'Add',
                      })
                      addEmail()
                    }}
                  >
                    {locale['Add']}
                  </Button>
                </Box>
              </Box>
              <List
                px={25}
                w="full"
                spacing={3}
                minH="250px"
                maxH="250px"
                overflowY="scroll"
              >
                {selectedUsers.length > 0
                  ? selectedUsers.map((s, u) => {
                      return (
                        <React.Fragment key={u}>
                          <ListItem>
                            <Flex spacing={2}>
                              <Center position="relative" alignSelf="center">
                                <Avatar
                                  name={s.name}
                                  bg="white"
                                  color="black"
                                  boxShadow="md"
                                  size="md"
                                />
                              </Center>
                              <Box ml={2} flex={1}>
                                <Text
                                  color="brand.800"
                                  textTransform="capitalize"
                                  fontSize="16px"
                                >
                                  {s.name}
                                </Text>
                                <Text color="brand.800" fontSize="12px">
                                  {s.designation} | {s.email}
                                </Text>
                              </Box>
                              <IconButton
                                bg="white"
                                borderRadius="md"
                                boxShadow="md"
                                aria-label="More"
                                size="sm"
                                _focus={{
                                  bg: 'secondary',
                                }}
                                _hover={{
                                  bg: 'secondary',
                                }}
                                icon={<Icon as={MdClose} w={6} h={6} />}
                                onClick={() => {
                                  sectionDetailedTrack({
                                    category: GA_CATEGORY_DIALOG_BOX,
                                    action: 'Add Participant',
                                    label: 'Remove User',
                                  })
                                  removeSelectedUser(s.email)
                                }}
                              />
                            </Flex>
                          </ListItem>
                          <Divider border="1px" borderColor="white" />
                        </React.Fragment>
                      )
                    })
                  : null}
              </List>
              <HStack
                alignItems="flex-end"
                display="flex"
                flexDirection="row"
                justifyContent="space-evenly"
                paddingY={5}
              >
                <Button
                  variant="outline"
                  bg="white"
                  borderRadius={6}
                  fontWeight="400"
                  boxShadow="md"
                  size="md"
                  w="150px"
                  onClick={e => {
                    sectionDetailedTrack({
                      category: GA_CATEGORY_DIALOG_BOX,
                      action: 'Add Participant',
                      label: 'Cancel',
                    })
                    onClose(e)
                  }}
                >
                  {locale['Cancel']}
                </Button>
                <Button
                  variant="filled"
                  bg={localStorage.getItem('color')}
                  color="white"
                  borderRadius={6}
                  fontWeight="400"
                  boxShadow="md"
                  size="md"
                  w="150px"
                  onClick={e => {
                    sectionDetailedTrack({
                      category: GA_CATEGORY_DIALOG_BOX,
                      action: 'Add Participant',
                      label: 'Submit',
                    })
                    onSubmit(e)
                  }}
                >
                  {locale['Submit']}
                </Button>
              </HStack>
            </VStack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default AddExtParticipant
