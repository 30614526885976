/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'

import { Box, Text, Tr, Td, Button } from '@chakra-ui/react'

import {
  addDays,
  isBefore,
  isAfter,
  parseISO,
  differenceInHours,
} from 'date-fns'
import './leave.scss'
import DatePicker from 'react-datepicker'
import { sectionDetailedTrack } from '../repository/repo.utils'
import { GA_CATEGORY_LEAVE } from './../repository/repo.utils'
import { useLanguageContext } from './../../context/locale.context'
export const LeaveTableEditForm = ({
  rowNumber,
  row,
  onCancel,
  onSave,
  leaveList,
}) => {
  const [startDate, setStartDate] = useState(
    parseISO(row.asset_leave_start_datetime)
  )
  const [endDate, setEndDate] = useState(parseISO(row.asset_leave_end_datetime))
  const [errorMsg, setErrorMsg] = useState('')
  const {
    state: { locale },
  } = useLanguageContext()

  useEffect(() => {
    setErrorMsg('')
  }, [startDate, endDate])
  useEffect(() => {
    let diff = Math.floor(differenceInHours(endDate, startDate) / 24)
    if (!!endDate && diff < 0) {
      setEndDate(addDays(startDate, 1))
    }
  }, [startDate])
  const handleSave = () => {
    let isValidDate = true
    leaveList.some((leave, index) => {
      if (index + 1 === rowNumber) return false
      let sdate = parseISO(leave.asset_leave_start_datetime)
      let edate = parseISO(leave.asset_leave_end_datetime)
      let isStartValid =
        isAfter(startDate, sdate) && isBefore(startDate, edate) ? false : true
      let isEndValid =
        isAfter(endDate, sdate) && isBefore(endDate, edate) ? false : true
      if (!isStartValid || !isEndValid) {
        isValidDate = false
        return true
      }
    })
    console.log(isValidDate)

    if (isValidDate) {
      onSave({ startDate, endDate, leave_workflow_id: row.leave_workflow_id })
    } else {
      setErrorMsg('Leave already applied for the given date.')
    }
    onSave()
  }
  return (
    <>
      <Tr
        bg="white"
        key={Math.random() * Date.now() + '1A'}
        m={2}
        w="full"
        borderRadius="sm"
        boxShadow="sm"
      >
        <Td p="2" pl="20px">
          {' '}
          {rowNumber}{' '}
        </Td>
        <Td p="2" pl="20px" textAlign={'center'}>
          <DatePicker
            showTimeSelect
            dateFormat="dd/MM/yyyy hh:mm a"
            className="date-css"
            selected={startDate}
            minDate={new Date()}
            onChange={date => {
              setStartDate(date)
            }}
          />
        </Td>
        <Td p="2" pl="20px" textAlign="center">
          <DatePicker
            showTimeSelect
            dateFormat="dd/MM/yyyy hh:mm a"
            className="date-css"
            selected={endDate}
            minDate={startDate}
            onChange={date => {
              setEndDate(date)
            }}
          />
          {errorMsg !== '' && (
            <Box>
              <Text color="red.400">{errorMsg}</Text>
            </Box>
          )}
        </Td>
        <Td p="2" pl="20px" textAlign="right">
          <Button
            bg={localStorage.getItem('color')}
            minW="100px"
            color="white"
            onClick={() => {
              sectionDetailedTrack({
                category: GA_CATEGORY_LEAVE,
                action: 'Leave Edit',
                label: 'Cancel',
              })
              onCancel()
            }}
          >
            {locale['Cancel']}
          </Button>
          <Button
            bg={localStorage.getItem('color')}
            ml="2px"
            minW="100px"
            color="white"
            onClick={() => {
              sectionDetailedTrack({
                category: GA_CATEGORY_LEAVE,
                action: 'Leave Edit',
                label: 'Save',
              })
              handleSave()
            }}
          >
            {locale['Save']}
          </Button>
        </Td>
      </Tr>
    </>
  )
}
export default LeaveTableEditForm
