/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react'
import {
	Flex,
	useDisclosure,
	Icon,
	HStack,
	Spacer,
	VStack,
	Box,
	Text,
	Button,
	Center,
	RadioGroup,
	Radio,
	Stack,
	Spinner,
	useTheme,
} from '@chakra-ui/react'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { BsPlus } from 'react-icons/bs'
import { FiArrowLeftCircle } from 'react-icons/fi'
import {
	useLoadActivityListV1,
	useLoadActivityParticipant,
	useExternalGuestsAdd,
	useMeetingEnable,
	useLoadActivityMeetingList,
} from '../../hooks/activity.hooks'
import { useWorkforceFormMap } from '../../hooks/form.hooks'
import { SmartFormContextProvider } from '../../context/smart-form.context'
import { useLanguageContext } from '../../context/locale.context'
import SmartFormPanel from '../form-panel/smart-form-panel'
import WorkspacePanel from '../../containers/workspace-panel/workspace-panel'
import { useSharedContext } from '../../context/shared.context'
import { sharedConst } from '../../utils/action.constant'
import ParticipantView from '../workspace-panel/team-collaboration/participant-view'
import AddExtParticipant from './add-meetings/events-form/add-participant'
import MeetingFormDialog from '../../components/meeting-forms-dialog'
import { PropagateLoader } from 'react-spinners'
// import {
// 	dataLayerTagManager,
// 	eventLabelTrack,
// 	GA_CATEGORY_MEETINGS,
// 	sectionDetailedTrack,
// } from './../repository/repo.utils'
import { endOfMonth, startOfMonth } from 'date-fns'
import { getCurrentDateUTC } from '../../utils/common.util'
import { getEndDateOfMonth, getStartDateOfMonth } from '../../utils/date.utils'

const localizer = momentLocalizer(moment)
const eventStyleGetter = (event, start, end, isSelected) => {
	var backgroundColor = '#48bb78'
	var style = {
		backgroundColor: backgroundColor,
		color: 'white',
		display: 'block',
		borderRadius: '0px',
	}
	return {
		style: style,
	}
}

const ColoredDateCellWrapper = ({ children }) =>
	React.cloneElement(React.Children.only(children), {
		style: {
			backgroundColor: '#fff',
		},
	})

const View = {
	CALENDER_VIEW: 'CALENDER_VIEW',
	FORM_VIEW: 'FORM_VIEW',
	ACTIVITY_VIEW: 'ACTIVITY_VIEW',
	PARTICIPANT_VIEW: 'PARTICIPANT_VIEW',
	EXT_PARTICIPANT_VIEW: 'EXT_PARTICIPANT_VIEW',
	CALENDER_VIEW_LOADER: 'CALENDER_VIEW_LOADER',
}

const MeetingsContainer = () => {
	const [events, setEvents] = useState([])
	const [activity, setActivity] = useState({})
	const { mutate: activityListV1Mutate } = useLoadActivityMeetingList()
	const { mutate: externalGuestsAddMutate } = useExternalGuestsAdd()
	const { mutate: activityParticipants } = useLoadActivityParticipant()
	const { mutate: workforceFormMapMutate } = useWorkforceFormMap()
	const { mutate: meetingEnable } = useMeetingEnable()
	const [meetingView, setmeetingView] = useState(View.CALENDER_VIEW)
	const [startDate, setStartDate] = useState(new Date())
	const [endDate, setEndDate] = useState(new Date())
	const [startDateTime, setStartDateTime] = useState(startOfMonth(new Date()))
	const [endDateTime, setEndDateTime] = useState(endOfMonth(new Date()))
	const {
		state: { isShowLoader, random },
		dispatch,
	} = useSharedContext()
	const [selectedUsers, setSelectedUsers] = useState([])
	const [count, setCount] = useState(1)
	const [meetingForms, setMeetingForms] = useState([])
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [formData, selectedForm] = useState({})
	const [isMeetingEnable, setMeetingEnable] = useState([])
	const [isloading, setIsloading] = useState(false)
	const [meetingActivityId, setMeetingActivityId] = useState(null)
	const chakraTheme = useTheme()
	const {
		state: { locale },
	} = useLanguageContext()

	const onSelectActivity = activity => {
		dispatch({
			type: sharedConst.ACTVITY_SELECTED,
			payload: activity,
		})
		setmeetingView(View.ACTIVITY_VIEW)
	}
	const handleNavigate = (date, view) => {
		if (view === 'month') {
			setStartDateTime(getStartDateOfMonth(date))
			setEndDateTime(getEndDateOfMonth(date))
		}
	}

	useEffect(() => {
		checkMeetingEnable()
	}, [startDateTime])

	useEffect(() => {
		if (meetingActivityId) {
			loadMapForm(meetingActivityId)
			loadEvents(meetingActivityId)
		}
	}, [meetingActivityId, isShowLoader.toString(), random])

	const setFormActivityId = activity_id => {
		if (!!activity_id) {
			setActivity({ activity_id })
			setmeetingView(View.PARTICIPANT_VIEW)
		} else {
			setmeetingView(View.CALENDER_VIEW)
		}
	}

	const addExternalUsers = () => {
		setmeetingView(View.EXT_PARTICIPANT_VIEW)
	}

	const doRefresh = (i = 0) => {
		let timer = setTimeout(() => {
			console.log('refresh ===> ', i, new Date().toLocaleString())
			dispatch({
				type: sharedConst.SHOW_LOADER,
			})
			doRefresh(i + 1)
		}, 3000)
		if (i > 3) {
			clearTimeout(timer)
			dispatch({
				type: sharedConst.HIDE_LOADER,
			})
		}
	}

	const loadEvents = activityId => {
		activityListV1Mutate(
			{
				sort_flag: 1,
				flag: 10,
				queue_id: 0,
				is_active: 0,
				is_due: 0,
				current_datetime: '',
				is_unread: 0,
				is_search: 0,
				search_string: '',
				status_type_id: 0,
				activity_status_id: 0,
				parent_activity_id: 0,
				activity_type_id: activityId,
				activity_type_category_id: 31,
				tag_type_id: 0,
				tag_id: 0,
				page_start: 0,
				page_limit: 1000,
				start_from: 0,
				limit_value: 1000,
				start_datetime: getCurrentDateUTC(startDateTime),
				end_datetime: getCurrentDateUTC(endDateTime),
			},
			{
				onSuccess: async ({ list }) => {
					let d = list?.map(a => {
						let start_date = new Date()
						let end_date = new Date()
						const dateString = a.activity_datetime_start_expected
						const dateString2 = a.activity_datetime_end_expected

						var stillUtc = moment.utc(dateString).toDate()
						var local = new Date(
							moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss')
						)

						var stillUtc1 = moment.utc(dateString2).toDate()
						var local1 = new Date(
							moment(stillUtc1).local().format('YYYY-MM-DD HH:mm:ss')
						)

						// console.log("start", moment(isoString).format('YYYY-MM-DD h:mm A'))
						return {
							...a,
							id: a.activity_id,
							title: a.activity_title,
							start: local,
							end: local1,
						}
					})
					setEvents(d)
				},
				onError: async err => {
					console.log('error occurred!', err)
				},
			}
		)
	}

	const handleUserSelect = e => {
		let uIndex = selectedUsers.findIndex(u => u === e)
		let users = uIndex === -1 ? [...selectedUsers, e] : [...selectedUsers]
		setSelectedUsers(users)
	}

	const removeSelectedUser = e => {
		let uIndex = selectedUsers.findIndex(u => u.email === e)
		if (uIndex > -1) {
			selectedUsers.splice(uIndex, 1)
		}
		setSelectedUsers(selectedUsers)
		setCount(count + 1)
	}

	const addGuests = () => {
		if (selectedUsers.length > 0) {
			addExternalGuests({
				emails: selectedUsers,
				workflow_activity_id: activity.activity_id,
				activity_type_id: activity.activity_type_id,
			})
		} else {
			alert('Enter email id')
		}
	}

	const addExternalGuests = useCallback(params => {
		setmeetingView(View.CALENDER_VIEW)
		externalGuestsAddMutate(
			{ ...params },
			{
				onSuccess: async data => {
					loadEvents(meetingActivityId)
				},
				onError: async data => {
					loadEvents(meetingActivityId)
				},
			}
		)
	})

	const checkMeetingEnable = () => {
		meetingEnable(
			{
				activity_type_category_id: 31,
				page_start: 0,
				page_limit: 30,
			},
			{
				onSuccess: async data => {
					setMeetingEnable(data.data.response)
					if (!!data.data.response.length) {
						setMeetingActivityId(
							data.data.response[0].form_workflow_activity_type_id
						)
					}
					loadMapForm(
						data.data.response?.[0]?.form_workflow_activity_type_id || 190797
					)
					loadEvents(
						data.data.response?.[0]?.form_workflow_activity_type_id || 190797
					)
				},
				onError: async err => {
					console.log(err)
				},
			}
		)
	}

	const loadMapForm = activityId => {
		workforceFormMapMutate(
			{
				activity_type_id: activityId,
				flag_origin: 1,
				page_start: 0,
				page_limit: 30,
			},
			{
				onSuccess: async data => {
					setMeetingForms(data)
				},
				onError: async err => {
					console.log(err)
				},
			}
		)
	}

	const selectMeetingForms = ({ start, end }) => {
		setStartDate(new Date(start))
		setEndDate(new Date(end))
		if (isMeetingEnable.length > 0) {
			if (!!meetingForms && meetingForms.length === 1) {
				handleSelectedForm(meetingForms[0])
			} else {
				onOpen()
			}
		} else {
			alert('You have no access to add meeting!')
		}
	}

	const handleSelectedForm = form => {
		selectedForm(form)
		onClose()
		setmeetingView(View.FORM_VIEW)
	}

	const formats = {
		eventTimeRangeFormat: e => {
			return null
		},
	}

	return (
		<VStack w='full' px={4} py={1} h='calc(100vh - 180px)'>
			<MeetingFormDialog
				isOpen={isOpen}
				onClose={onClose}
				formList={meetingForms}
				selectedForm={e => {
					handleSelectedForm(e)
				}}
			/>
			{meetingView === View.CALENDER_VIEW && (
				<>
					<HStack w='full' my={1} justifyContent='space-between'>
						<Text fontSize='large' fontWeight='bold' paddingY='2'>
							{locale['Meetings']}
						</Text>
						<Spacer />
						<Button
							leftIcon={<Icon as={BsPlus} w={8} h={8} />}
							variant='filled'
							bg={localStorage.getItem('color')}
							color='white'
							borderRadius={4}
							fontWeight='400'
							boxShadow='md'
							size='sm'
							isDisabled={
								!isMeetingEnable.length || meetingView !== View.CALENDER_VIEW
							}
							onClick={() => {
								// dataLayerTagManager('button_click', {
								// 	viewName: 'Meetings',
								// 	buttonName: 'Add New',
								// })
								if (!!meetingForms && meetingForms.length === 1) {
									// eventLabelTrack({
									// 	categoryId: 1,
									// 	actionId: 0,
									// 	label: `New Form ${meetingForms[0].form_name}`,
									// })
									handleSelectedForm(meetingForms[0])
								} else {
									onOpen()
								}
							}}
						>
							{locale['Add New']}
						</Button>
					</HStack>
					{isShowLoader && (
						<Center p={2} my={1}>
							<Spinner
								thickness='4px'
								speed='0.65s'
								emptyColor={chakraTheme.colors.secondary}
								color={chakraTheme.colors.brand[800]}
								size='md'
							/>
						</Center>
					)}
					<RadioGroup
						alignSelf='flex-start'
						onChange={e => {
							const radioText = isMeetingEnable.find(val => {
								if (val.form_workflow_activity_type_id === parseInt(e))
									return true
								return false
							})
							// dataLayerTagManager('button_click', {
							// 	viewName: 'Meetings',
							// 	buttonName: radioText.form_workflow_activity_type_name,
							// })
							setMeetingActivityId(Number(e))
						}}
						value={meetingActivityId}
					>
						<Stack direction='row'>
							{!!isMeetingEnable.length &&
								isMeetingEnable.map((val, i) => (
									<Radio
										color={localStorage.getItem('color')}
										value={val.form_workflow_activity_type_id}
										key={i}
									>
										{val.form_workflow_activity_type_name}
									</Radio>
								))}
						</Stack>
					</RadioGroup>
				</>
			)}

			<HStack w='full' alignItems='flex-start' bg='white' p={4}>
				{meetingView === View.CALENDER_VIEW ? (
					<Box h='calc(100vh - 205px)' w='full'>
						<BigCalendar
							formats={formats}
							titleAccessor={function (e) {
								return e.title
							}}
							selectable
							localizer={localizer}
							events={events}
							views={['month', 'week', 'day']}
							defaultView='month'
							startAccessor='start'
							endAccessor='end'
							style={{ width: '100%', height: '100%' }}
							components={{
								timeSlotWrapper: ColoredDateCellWrapper,
								event: e => (
									<Text>
										<Text color='white' fontWeight={'400'} fontSize={14}>
											{e.title}
										</Text>
										<Text color='white' fontSize={12}>
											{`${moment(e.event.start).format('hh:mm A')} -- ${moment(
												e.event.end
											).format('hh:mm A')}`}
										</Text>
									</Text>
								),
							}}
							onSelectEvent={event => {
								// dataLayerTagManager('button_click', {
								// 	viewName: 'Meetings',
								// 	buttonName: 'New Meeting Form',
								// })

								onSelectActivity(event)
							}}
							onSelectSlot={({ start, end }) => {
								// dataLayerTagManager('button_click', {
								// 	viewName: 'Meetings',
								// 	buttonName: 'Select Form',
								// })
								selectMeetingForms({ start, end })
							}}
							onNavigate={handleNavigate}
							eventPropGetter={eventStyleGetter}
						/>
					</Box>
				) : meetingView === View.FORM_VIEW ? (
					<Box
						w='full'
						bg='white'
						borderRadius='md'
						overflow='scroll'
						height='calc(100vh - 60px)'
					>
						<SmartFormContextProvider>
							<SmartFormPanel
								onClose={(data = null) => {
									loadEvents(meetingActivityId)
									setIsloading(false)
									doRefresh(0)
									setTimeout(() => {
										setmeetingView(View.CALENDER_VIEW)
									}, 1000)
								}}
								formData={formData}
								isOrigin={true}
								activity={{}}
								startDate={startDate}
								endDate={endDate}
								module='meeting'
								handleError={() => {
									console.log('error occurred!')
								}}
							/>
						</SmartFormContextProvider>
					</Box>
				) : meetingView === View.PARTICIPANT_VIEW ? (
					<ParticipantView
						activity={activity}
						showExternal={true}
						isOpen={meetingView === View.PARTICIPANT_VIEW}
						onClose={() => {
							setmeetingView(View.CALENDER_VIEW)
							loadEvents(meetingActivityId)
						}}
						addExternalUsers={addExternalUsers}
					/>
				) : meetingView === View.EXT_PARTICIPANT_VIEW ? (
					<AddExtParticipant
						selectedUsers={selectedUsers}
						handleUserSelect={handleUserSelect}
						removeSelectedUser={removeSelectedUser}
						isOpen={meetingView === View.EXT_PARTICIPANT_VIEW}
						onClose={() => {
							setmeetingView(View.CALENDER_VIEW)
							loadEvents(meetingActivityId)
						}}
						onSubmit={addGuests}
					/>
				) : (
					<Box
						w='full'
						bg='white'
						borderRadius='md'
						overflow='scroll'
						height='calc(100vh - 60px)'
					>
						<VStack w='full' justifyContent='flex-start' px={0}>
							<HStack
								my={4}
								w='full'
								p={2}
								marginY={2}
								mx={4}
								bg='secondary'
								maxH='70px'
							>
								<Icon
									className={`meetings-container-back-icon`}
									as={FiArrowLeftCircle}
									cursor='pointer'
									color={localStorage.getItem('color')}
									w={8}
									h={8}
									onClick={() => {
										// sectionDetailedTrack({
										// 	category: GA_CATEGORY_MEETINGS,
										// 	action: 'Add New Meeting',
										// 	label: 'Go Back',
										// })
										// dataLayerTagManager('button_click', {
										// 	viewName: 'Meetings',
										// 	buttonName: 'Back to Meetings',
										// })
										setmeetingView(View.CALENDER_VIEW)
										loadEvents(meetingActivityId)
									}}
								/>
								<Text fontSize='md' fontWeight='600' textAlign='center'>
									{locale['Back to Meetings']}
								</Text>
							</HStack>
						</VStack>
						<WorkspacePanel />
					</Box>
				)}
			</HStack>
		</VStack>
	)
}

export default MeetingsContainer
