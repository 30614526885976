/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Spacer, Button, HStack, Text } from '@chakra-ui/react'
import {
	addDays,
	addMinutes,
	isAfter,
	isBefore,
	parseISO,
	roundToNearestMinutes,
	differenceInHours,
} from 'date-fns'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useLanguageContext } from '../../context/locale.context'
import '../export-panel/export/export.scss'
// import {
//   dataLayerTagManager,
//   sectionDetailedTrack,
// } from '../repository/repo.utils'
import { GA_CATEGORY_LEAVE } from './../repository/repo.utils'

export default function ApplyLeaveForm({ leaveList, onApplyLeave, onClose }) {
	const [startDate, setStartDate] = useState(
		roundToNearestMinutes(new Date(), { nearestTo: 30 })
	)
	const [endDate, setEndDate] = useState(addDays(new Date(), 1))
	const [errorMsg, setErrorMsg] = useState('')
	const {
		state: { locale },
	} = useLanguageContext()
	useEffect(() => {
		let date = roundToNearestMinutes(new Date(), { nearestTo: 30 })
		let sDate = isBefore(date, new Date()) ? addMinutes(date, 30) : date
		setStartDate(sDate)
	}, [])
	useEffect(() => {
		setErrorMsg('')
	}, [startDate, endDate])
	useEffect(() => {
		let diff = Math.floor(differenceInHours(endDate, startDate) / 24)
		if (!!endDate && diff < 0) {
			setEndDate(addDays(startDate, 1))
		}
	}, [startDate])

	const onLeaveSave = e => {
		e.preventDefault()
		let isValidDate = true
		for (let leave of leaveList) {
			let sdate = parseISO(leave.asset_leave_start_datetime) //moment(leave.asset_leave_start_datetime);
			let edate = parseISO(leave.asset_leave_end_datetime) //moment(leave.asset_leave_end_datetime);
			console.log(sdate)
			console.log(edate)
			let isStartValid =
				isAfter(startDate, sdate) && isBefore(startDate, edate) ? false : true //moment(value.leave_start).isBetween(sdate, edate);
			let isEndValid =
				isAfter(endDate, sdate) && isBefore(endDate, edate) ? false : true //moment(value.leave_end).isBetween(sdate, edate);
			if (
				!isStartValid ||
				!isEndValid ||
				sdate.toString() === startDate.toString() ||
				edate.toString() === endDate.toString()
			) {
				isValidDate = false
				break
			}
		}

		if (isValidDate) {
			onApplyLeave({ startDate, endDate })
		} else {
			setErrorMsg(locale['Leave already applied for the given date.'])
		}
	}

	return (
		<Box p={2} bg='white' borderRadius='sm' boxShadow='sm' w='65%'>
			<form>
				<HStack justifyContent='space-between'>
					<HStack>
						<DatePicker
							showTimeSelect
							dateFormat='dd/MM/yyyy hh:mm a'
							className='date-css'
							selected={startDate}
							minDate={new Date()}
							onChange={date => {
								setStartDate(date)
							}}
						/>
						<Spacer />
						<DatePicker
							showTimeSelect
							dateFormat='dd/MM/yyyy hh:mm a'
							className='date-css'
							selected={endDate}
							minDate={startDate}
							onChange={date => {
								setEndDate(date)
							}}
						/>
					</HStack>
					<Spacer />

					<HStack spacing={2}>
						<Button
							variant='outline'
							size='sm'
							minW='100px'
							colorScheme={localStorage.getItem('color')}
							type='submit'
							borderRadius='sm'
							onClick={e => {
								// sectionDetailedTrack({
								//   category: GA_CATEGORY_LEAVE,
								//   action: 'Apply Leave Form',
								//   label: locale['Cancel'],
								// })
								// dataLayerTagManager('button_click', {
								//   viewName: 'Unavailability',
								//   buttonName: 'Cancel',
								// })
								onClose(e)
							}}
						>
							{locale['Cancel']}
						</Button>
						<Button
							variant='solid'
							size='sm'
							colorScheme={localStorage.getItem('color')}
							bg={localStorage.getItem('color')}
							type='submit'
							borderRadius='sm'
							minW='100px'
							onClick={e => {
								// sectionDetailedTrack({
								//   category: GA_CATEGORY_LEAVE,
								//   action: 'Apply Leave Form',
								//   label: locale['Apply'],
								// })
								// dataLayerTagManager('button_click', {
								//   viewName: 'Unavailability',
								//   buttonName: 'Apply',
								// })
								onLeaveSave(e)
							}}
						>
							{locale['Apply']}
						</Button>
					</HStack>
				</HStack>
				{errorMsg !== '' && <Text color='red.400'>{errorMsg}</Text>}
			</form>
		</Box>
	)
}
